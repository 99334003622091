<template>
  <div class="account">
    <!--CRow>
      <CCol sm>
        <payment-method></payment-method>
      </CCol>
    </CRow-->
    <div v-if="!loadAbo">
      <CRow class="justify-content-center mb-5">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </CRow>
    </div>
    <div v-else>
      <h3>{{ $t("titre1_plans") }}</h3>
      <CRow>
        <CCol
          v-for="abo in abos"
          :key="abo.id"
          sm="12"
          md="6"
          xl="3"
          class="mb-4 col-flex"
        >
          <CCard style="width: 100%; max-width: 735px">
            <CCardHeader
              
            >
            <!-- :class="{ mediumAbo: abo.type == 'full' ? true : false }" -->
              <h6>
                <span>{{ $t(abo.type) }}</span>
                <!--span
                  v-if="abo.type == 'full'"
                  class="material-symbols-outlined"
                  >star</span
                -->
              </h6>
              <div class="block-prix">
                <div class="prix">
                  <span>{{ abo.price }}</span> €
                </div>
                <div class="month">/ {{ $t("month") }} ({{ $t("HT") }})</div>
              </div>
            </CCardHeader>
            <CCardBody>
              <ul v-if="abo.type == 'free'" class="listAbo">
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span v-html="$t('acces_1_ecran')"></span>
                </li>
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span v-html="$t('3_photos')"></span>
                </li>
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span v-html="$t('stockage_free')"></span>
                </li>

                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span>{{ $t("dispo_androidtv_free") }}</span>
                </li>
                <li>
                  <span class="material-symbols-outlined green">cancel</span
                  ><span>{{ $t("en_option") }}</span>
                </li>

                <li>
                  <span class="material-symbols-outlined red">cancel</span
                  ><span>{{ $t("poss_rasbery") }}</span>
                </li>

                <li>
                  <span class="material-symbols-outlined red">cancel</span
                  ><span>{{ $t("pas_programmation") }}</span>
                </li>
                <li>
                  <span class="material-symbols-outlined red">cancel</span
                  ><span>{{ $t("choix_oriention_photos") }}</span>
                </li>
                <li>
                  <span class="material-symbols-outlined red">cancel</span
                  ><span>{{ $t("perso_texte_bas") }}</span>
                </li>
              </ul>
              <ul v-else-if="abo.type == 'medium'" class="listAbo">
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span v-html="$t('acces_5_ecran')"></span>
                </li>
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span v-html="$t('nombre_media_ill')"></span>
                </li>
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span v-html="$t('stockage_basic')"></span>
                </li>

                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span>{{ $t("dispo_androidtv") }}</span>
                </li>
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span v-html="$t('visuel_basic')"></span>
                </li>
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span>{{ $t("poss_rasbery") }}</span>
                </li>

                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span>{{ $t("pas_programmation") }}</span>
                </li>
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span>{{ $t("choix_oriention_photos") }}</span>
                </li>
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span>{{ $t("perso_texte_bas") }}</span>
                </li>
              </ul>
              <ul v-else-if="abo.type == 'full'" class="listAbo">
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span v-html="$t('acces_20_ecran')"></span>
                </li>
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span v-html="$t('nombre_media_ill')"></span>
                </li>
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span v-html="$t('stockage_business')"></span>
                </li>

                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span>{{ $t("dispo_androidtv") }}</span>
                </li>
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span v-html="$t('visuel_business')"></span>
                </li>

                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span>{{ $t("poss_rasbery") }}</span>
                </li>

                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span>{{ $t("pas_programmation") }}</span>
                </li>
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span>{{ $t("choix_oriention_photos") }}</span>
                </li>
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span>{{ $t("perso_texte_bas") }}</span>
                </li>
              </ul>
              <ul v-else class="listAbo">
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span v-html="$t('acces_illim_ecran')"></span>
                </li>
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span v-html="$t('nombre_media_ill')"></span>
                </li>
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span v-html="$t('stockage_pro')"></span>
                </li>

                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span>{{ $t("dispo_androidtv") }}</span>
                </li>
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span v-html="$t('visuel_pro')"></span>
                </li>

                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span>{{ $t("poss_rasbery") }}</span>
                </li>

                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span>{{ $t("pas_programmation") }}</span>
                </li>
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span>{{ $t("choix_oriention_photos") }}</span>
                </li>
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span>{{ $t("perso_texte_bas") }}</span>
                </li>
              </ul>
            </CCardBody>

            <CCardFooter
              align="center"
              v-if="
                (abo_user.type == 'free' && abo.type == 'free') ||
                abo_user.id <= abo.id
              "
            >
              <div v-if="abo_user.type == abo.type" class="text-blue">
                {{ $t("offre_actuelle") }}
              </div>

              <span v-if="abo_user.type == 'free' && abo.type != 'free'">
                <button class="btn btn-primary" @click="buy(abo.id)">
                  {{ $t("subscribe") }}
                </button>
              </span>
              <span v-else>
                <span v-if="abo.id > abo_user.id && abo_user.type != 'pro'">
                  <button
                    class="btn btn-primary"
                    @click="upgradeToPremium(abo.id)"
                  >
                    {{ $t("up_to_prem") }}
                  </button>
                </span>
              </span>

              <!--span v-if="abo.type != 'free' && abo_user.type == abo.type">
              <button class="btn btn-primary" @click="cancel()">
                {{ $t("cancel_sub") }}
              </button>
              <CAlert v-if="errorCancel" color="danger">{{
                errorCancel
              }}</CAlert>
            </span-->
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
      <div v-if="this.abo_user.type != 'free'">
        <h3>{{ $t("titre2_plans") }}</h3>
        <CRow>
          <CCol
            v-for="pack in packs"
            :key="pack.id"
            sm="12"
            md="6"
            xl="4"
            class="mb-4 col-flex"
          >
            <CCard style="width: 100%; max-width: 735px">
              <CCardHeader>
                <h6>
                  <span>{{ $t(pack.type) }}</span>
                </h6>
                <div class="block-prix">
                  <div class="prix">
                    <span>{{ pack.price }}</span> €
                  </div>
                  <div class="month">/ {{ $t("month") }} ({{ $t("HT") }})</div>
                </div>
              </CCardHeader>
              <CCardBody>
                <ul  class="listAbo">
                <li>
                  <span class="material-symbols-outlined green"
                    >check_circle</span
                  ><span v-html="$t('text_'+pack.type)"></span>
                </li>
                </ul>
              </CCardBody>

              <CCardFooter align="center">
                <span>
                  <button class="btn btn-primary" @click="buyPack(pack.id)">
                    {{ $t('buy') }}
                  </button>
                </span>
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>
      </div>
    </div>

    <div v-if="this.abo_user.type != 'free'">
    <h3>{{ $t("titre3_plans") }}</h3>
    <CRow>
      <CCol sm>
        <CCard style="width: 100%">
          <CCardBody>
            <CRow>
              <CCol style="text-align: center"
                ><img
                  src="../assets/RasperryPi.jpg"
                  style="width: 100%; max-width: 380px"
              /></CCol>
              <CCol>
                <h4 style="margin-bottom: 20px">
                  {{ $t("title_rbpi_block") }}
                </h4>
                <p>
                  {{ $t("line1_rbpi_block") }}
                </p>
                <p>
                  {{ $t("line2_rbpi_block") }}
                </p>
                <div style="text-align: center; margin-top: 40px">
                  <button class="btn btn-primary" @click="buyRbpi()">
                    {{ $t("order_rsbp") }}
                  </button>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>

    <CRow v-if="orders && orders.length > 0">
      <CCol sm>
        <CCard style="width: 100%">
          <CCardBody>
            <table class="tabOrders">
              <tr>
                <th scope="col">{{ $t("date_commande") }}</th>
                <th scope="col">{{ $t("info_shp") }}</th>
                <th scope="col">{{ $t("n_rbpi") }}</th>
                <th scope="col">{{ $t("status_order") }}</th>
              </tr>

              <tr v-for="o in orders" :key="o.id">
                <td>{{ moment(o.created_at) }}</td>
                <td>
                  {{ o.shipping_info.address.line1 }}<br />
                  {{ o.shipping_info.address.postal_code }}
                  {{ o.shipping_info.address.city }}
                </td>
                <td>{{ o.id_material }}</td>
                <td>{{ o.order_status }}</td>
              </tr>
            </table>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!--router-link to="/dashboard/about">About</router-link-->
  </div>
</template>

<script>
//import PaymentMethod from "../components/account/PaymentMethod.vue";
import moment from "moment";
export default {
  name: "Account",
  /*components: {
    PaymentMethod,
  },*/
  data() {
    return {
      errors: {},
      loading: false,
      session_stripe_id: null,
      abos: null,
      errorCancel: null,
      abo_user: {},
      orders: null,
      loadAbo: false,
      packs: null,
      pack_user: {},
    };
  },
  computed: {
    t99: function () {
      return "https://www.trucker360.agency/user/login?l=" + this.$i18n.locale;
    },
  },
  methods: {
    moment(d) {
      return moment(d).format("DD-MM-YYYY");
    },
    async updateData() {
      let response = await window.axios.put("/api/update", this.form);
      this.$store.commit("setAuth", response.data.user);
      console.log("up", this.$store.state.user);
    },
    async logout() {
      try {
        let r = await window.axios.post("/logout");
        console.log("logout", r);
        this.$store.commit("unAuth");
      } catch (error) {
        console.log(error);
      }
    },
    async buy(id_abo) {
      let loader = this.$loading.show({ canCancel: false });
      try {
        const rep = await window.axios.get("/api/stripesession/" + id_abo);
        //rep.data.media
        //this.session_stripe_id = rep.data.rep.id
        window.open(rep.data.rep.url, "_self");
      } catch (err2) {
        console.log("FAILURE 2!!", err2);
        loader.hide();
      }
    },
    async buyPack(id_pack) {
      let loader = this.$loading.show({ canCancel: false });
      try {
        const rep = await window.axios.get("/api/stripesessionpack/" + id_pack);
        window.open(rep.data.rep.url, "_self");
      } catch (err2) {
        console.log("FAILURE 2!!", err2);
        loader.hide();
      }
    },
    async upgradeToPremium(id_abo) {
      console.log("HERE : ", id_abo);

      let loader = this.$loading.show({ canCancel: false });
      try {
        await window.axios.get("/api/upgradesubscription/" + id_abo);
        //rep.data.media
        //this.session_stripe_id = rep.data.rep.id
        window.location.reload();
      } catch (err2) {
        console.log("FAILURE 2!!", err2);
        loader.hide();
      }
    },
    async buyRbpi() {
      let loader = this.$loading.show({ canCancel: false });
      try {
        const rep = await window.axios.get("/api/stripesessionrbpi");
        //rep.data.media
        //this.session_stripe_id = rep.data.rep.id
        window.open(rep.data.rep.url, "_self");
      } catch (err2) {
        console.log("FAILURE 2!!", err2);
        loader.hide();
      }
    },
    async cancel() {
      this.$confirm({
        message: this.$t("confirmation"),
        button: {
          no: this.$t("non"),
          yes: this.$t("oui"),
        },
        callback: async (confirm) => {
          if (confirm) {
            // ... do something
            let loader = this.$loading.show({ canCancel: false });
            try {
              await window.axios.delete("/api/screen/all");
              await window.axios.put("/api/cancelsubscription");
              loader.hide();
              window.location.reload();
            } catch (err2) {
              console.log("FAILURE 2!!", err2);
              loader.hide();
              this.errorCancel = "error_cancel_subscription";
            } /*
            try {
              await window.axios.put("/api/cancelsubscription");
              loader.hide();
              window.location.reload();
              //TODO : relod + message
            } catch (err2) {
              console.log("FAILURE 2!!", err2);
              loader.hide();
              this.errorCancel = "error_cancel_subscription";
            }*/
          }
        },
      });
    },
  },
  mounted: function () {
    if (this.$store.state.user.abos[0])
      // si authentifié
      this.abo_user = this.$store.state.user.abos[0];
  },
  async created() {
    const rep = await window.axios.get("/api/allabo");
    this.abos = rep.data.data;

    const repPack = await window.axios.get("/api/allpacks");
    this.packs = repPack.data.data;

    const repOrders = await window.axios.get("/api/orders");
    this.orders = repOrders.data;
    this.loadAbo = true;
    /* var t = moment('2022-05-25T00:00:00.000000Z').format('hh:mm a');
    console.log(t)*/
  },
};
</script>

<style scoped>
h6,
h4 {
  color: #f45a2a;
  font-weight: bold;
}

.block-prix {
  display: flex;
  align-items: center;
}

.prix {
  font-size: 42px;
  font-weight: bold;
  margin-right: 5px;
}

li {
  font-size: 16px;
  padding-bottom: 8px;
  padding-top: 8px;
}

.col-flex {
  display: flex;
  padding-left: 7px;
  padding-right: 7px;
}
.text-blue {
  padding: 7px;
}

.tabOrders {
  width: 100%;
}

td {
  padding-bottom: 5px;
  padding-top: 5px;
}

ul.listAbo {
  list-style: none;
  padding-left: 0;
}

ul.listAbo li {
  display: flex;
  align-items: start;
  gap: 5px;
  padding-bottom: 12px;
}

.green {
  color: rgb(1, 211, 1);
}

.red {
  color: red;
}

b {
  color: #f45a2a;
}

.mediumAbo {
  background-color: #f45a2a;
  color: white;
}

h6 span{
  text-transform: uppercase;
}

.mediumAbo h6 {
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
}

.card-header h6 {
  height: 24px;
}
</style>
